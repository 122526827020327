/* ----------------------------------
Vivid
Version: 1.0
Author: BootEx
Author URL: http://www.ahadhossain.com

-------------------------------------

Table of contents

  @-> Main Styles
  @-> Navigation
  @-> Intro 
  @-> About 
  @-> Service
  @-> Resume
  @-> Portfolio
  @-> Review
  @-> Contact
  @-> Footer
  @-> Responsive

*/
/*--------------------
	Main Styles
----------------------*/

@import "bootstrap.min.css";
@import "animate.min.css";
@import "magnific-popup.css";
@import "owl.carousel.min.css";

html, body, #root, .myHomepage {
  height: 100%;
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
 }

img {
  max-width: 100%; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  font-weight: 400;
  color: #BBB; }

p {
  font-size: 14px;
  color: #989898;
  line-height: 24px; }

button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none; }

.myresumelink {
  font-weight: bold;
  color: white;
  background-color: #298ef6;
  border-radius: 20px;
  padding-left:10px;
  padding-right: 10px;
  pointer-events: auto !important;  
  cursor: pointer !important;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none; }

ul, ol {
  padding: 0;
  margin: 0; }

.spad {
  padding-top: 50px;
  padding-bottom: 30px; }

.section-title {
  text-align: center;
  margin-bottom: 70px; }
  .section-title h2 {
    font-size: 36px;
    font-weight: 700;
    text-transform: capitalize;
    color: #000; }

.set-bg {
  background-repeat: no-repeat;
  background-size: cover; }

.site-btn {
  font-size: 12px;
  background: #298ef6;
  border: none;
  color: #fff;
  padding: 14px 30px;
  border-radius: 50px;
  min-width: 160px;
  cursor: pointer; }

/* Image Popup */
.img-popup-warp .mfp-content,
.img-popup-warp.mfp-ready.mfp-removing .mfp-content {
  opacity: 0;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  z-index: 999;
  transition: all 0.4s; }

.img-popup-warp.mfp-ready .mfp-content {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  z-index: 999;
  transform: scale(1); }

/* Preloder */
#preloder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff; }

.loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;
  border-radius: 60px;
  animation: loader 0.8s linear infinite;
  -webkit-animation: loader 0.8s linear infinite; }

@keyframes loader {
  0% {
    transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent; }
  50% {
    transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent; }
  100% {
    transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent; } }
@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent; }
  50% {
    -webkit-transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent; }
  100% {
    -webkit-transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent; } }
/*--------------------
	Navigation
----------------------*/
.nav-toggle {
  position: fixed;
  display: block;
  width: 30px;
  height: 60px;
  right: 50px;
  top: 50px;
  z-index: 99;
  text-align: right;
  cursor: pointer; }
  .nav-toggle span {
    display: block;
    transition: all 0.3s ease-out 0s; }
    .nav-toggle span:nth-child(1), .nav-toggle span:nth-child(2), .nav-toggle span:nth-child(3) {
      width: 100%;
      height: 4px;
      background: #989898;
      margin-bottom: 5px;
      float: right; }
    .nav-toggle span:nth-child(3) {
      width: 70%; }
    .nav-toggle:hover span {
      background: #fff;
    }
  .nav-toggle:hover span:nth-child(3) {
    width: 100%; }
  .nav-toggle.sticky span {
    background: #000; }

.nav-section {
  overflow: hidden;
  position: fixed;
  width: 0;
  height: 100%;
  right: 0;
  top: 0;
  padding-top: 10%;
  background: #fff;
  z-index: 999;
  transition: all 0.5s cubic-bezier(0.55, 0.09, 0.68, 0.53) 0.4s; }
  .nav-section.active {
    width: 40%;
    box-shadow: 0 0 100px rgba(72, 72, 72, 0.15);
    transition: all 0.5s cubic-bezier(0.55, 0.09, 0.68, 0.53) 0s; }
    .nav-section.active .main-manu {
      top: 0;
      opacity: 1;
      transition: all 0.4s ease 0.5s; }
    .nav-section.active .social-links,
    .nav-section.active .close-nav {
      opacity: 1;
      transition: all 0.4s ease 0.5s; }

.nav-bar {
  position: fixed;
  display: block;
  width: 100%;
  /* height: 40px; */
  top: 0px;
  backdrop-filter: blur(20px);
  z-index: 999; }
  .nav-bar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .nav-bar li {
    padding: 14px 10px;
    /* display: inline; */
    display: inline-block;
  }
  .nav-bar li a {
    /* color:rgba(255, 255, 255, 0.5); */
    color: #aaa;
  }

.close-nav {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 1px solid #999;
  color: #999;
  font-weight: 300;
  font-size: 22px;
  text-align: center;
  border-radius: 50px;
  padding-top: 4px;
  top: 20px;
  cursor: pointer;
  right: 20px;
  opacity: 0;
  z-index: 99999;
  transform: rotate(45deg);
  transform-origin: left center;
  transition: all 0.3s ease 0s; }

.main-manu {
  position: relative;
  padding-left: 100px;
  padding-right: 100px;
  opacity: 0;
  top: 80px;
  transition: all 0.3s ease 0s; }
  .main-manu ul {
    height: 500px;
    overflow-y: auto;
    list-style: none; }
  .main-manu a {
    font-size: 35px;
    font-weight: 600;
    color: #444;
    display: block;
    margin-bottom: 5px;
    position: relative;
    transition: all 0.3s ease 0s; }
    .main-manu a:after {
      position: absolute;
      content: '';
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background: #444;
      opacity: 0;
      transition: all 0.3s ease 0s;
      z-index: -1; }
  .main-manu li.current a:after {
    opacity: 1;
    width: 35px; }

/* .social-links {
  position: absolute;
  bottom: 50px;
  left: 100px;
  opacity: 0;
  transition: all 0.3s ease 0s; }
  .social-links a {
    font-size: 25px;
    color: #999;
    margin-right: 20px;
    transition: all 0.3s ease-out 0s; }
    .social-links a:hover {
      color: #222; } */

/*--------------------
	Intro Section
----------------------*/
.intro-section {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative; }
  .intro-section:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.5; }

.intro-content {
  width: 100%;
  height: 100%;
  display: table;
  position: relative;
  z-index: 1; }
  .intro-content .intro-inner {
    display: table-cell;
    vertical-align: middle; }
  .intro-content h2 {
    color: #fff;
    font-weight: 300;
    font-size: 80px;
    margin-bottom: 10px; }
    .intro-content h2 span {
      font-weight: 700;
      /* color: rgb(138, 198, 221); */
      /* background-image: url("../img/text-bg.jpeg"); */
      /* -webkit-text-fill-color: transparent; */
      -webkit-background-clip: text;
      background-position: center center;
      background-size: cover;
      text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.1); }
  .intro-content h3 {
    color: #EEE;
    font-size: 20px;
    font-weight: 400;
    /* text-transform: uppercase; */
    letter-spacing: 1px; }

#particles {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

/* 
.highlight {
  color: rgb(138, 198, 221);
  font-weight: 500;
} */

/* .social-links {} */

.social-links a {
  /* color: rgb(138, 198, 221); */
  margin-right: 20px;
  font-size: x-large;
}

/*--------------------
	About Section
----------------------*/

.about-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about-text h1 {
  margin-bottom: 40px;
  font-weight: 600; }
.about-text h3 {
  font-weight: 400;
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  font-style: italic; }
.about-text p {
  margin-bottom: 30px; 
  color: darkslategrey;
}
  .about-text p span {
    font-weight: bold;
    color: #298ef6; }

/*--------------------
	Skill Section
----------------------*/
.service-box {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  height: 300px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px; }
  .service-box:hover .service-content {
    opacity: 1; }
  .service-box:hover .service-icon {
    opacity: 0;
    top: 0; }

.service-icon {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  font-size: 30px;
  text-align: center;
  background: #fff;
  display: block;
  line-height: 30px;
  color: #333;
  padding-top: 26px;
  border-radius: 100px;
  box-shadow: 3px 4px 30px rgba(0, 0, 0, 0.25);
  opacity: 1;
  transition: all 0.3s ease-out 0s; }
  .service-icon h2 {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    margin-top: 40px;
    margin-left: -80px;
    width: 300%
  }

.service-content {
  width: 100%;
  height: 100%;
  display: table;
  text-align: center;
  z-index: 1;
  background: rgba(255, 255, 255, 0.4);
  padding: 20px;
  opacity: 0;
  transition: all 0.4s ease 0s; }
  .service-content .service-inner {
    display: table-cell;
    vertical-align: middle;
    z-index: 1;
    position: relative; }
  .service-content h2 {
    font-weight: 600;
    font-size: 25px;
    color: #fff;
    margin-bottom: 20px; }
  .service-content p {
    color: #222; }
  .service-content:after {
    position: absolute;
    content: '';
    width: 120%;
    height: 120%;
    left: -10%;
    top: -10%;
    /* background-image: url("../img/aboutmebg.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top;
    filter: blur(16px); }

/*--------------------
	Resume Section
----------------------*/
.resume-section {
  background: #f2f2f2; }

.resume-item {
  padding: 30px;
  background: #fff;
  box-shadow: -1px 3px 30px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
  position: relative; }
  .resume-item:last-child {
    margin-bottom: 0; }
    .resume-item:last-child:after {
      display: none; }
  .resume-item:after {
    position: absolute;
    content: "";
    height: 30px;
    border-right: 2px dashed #ddd;
    top: 100%;
    left: 25px; }
  .resume-item h2 {
    font-size: 20px;
    margin-bottom: 15px; }
  .resume-item h3 {
    font-size: 12px;
    margin-bottom: 10px;
    color: #298ef6;
    font-weight: 600;
    text-transform: uppercase; }
  .resume-item p {
    margin-bottom: 0; }

/*-----------------------
	Portfolio Section
-------------------------*/
.portfolio-filter {
  list-style: none;
  text-align: center;
  margin-bottom: 40px; }

.portfolio-filter li {
  display: inline-block;
  margin: 0 5px;
  font-size: 13px;
  padding: 3px 0;
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }

.portfolio-filter li.active {
  background: #f2f2f2;
  padding: 3px 12px;
  border-radius: 20px; }

.isotope_items {
  margin-bottom: -30px; }

.portfolio-item {
  height: 280px;
  background-position: center top;
  margin-bottom: 10px;
  display: block;
  position: relative; }
  .portfolio-item:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0;
    transition: all 0.4s ease 0s; }
  .portfolio-item .portfolio-text {
    position: absolute;
    left: 20px;
    bottom: 20px;
    overflow: hidden;}
    .portfolio-item .portfolio-text h2 {
      font-size: 27px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 5px;
      position: relative;
      left: 0px;
      opacity: 1;}
    .portfolio-item .portfolio-text p {
      font-size: 12px;
      color: #fff;
      position: relative;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease 0s; }
      .portfolio-item .portfolio-text p:after {
        position: absolute;
        content: '';
        width: 0;
        height: 1px;
        left: 0;
        top: 12px;
        background: #fff;
        transition: all 0.4s ease 0s; }
  .portfolio-item:hover:after {
    opacity: 0.5; }
  .portfolio-item:hover .portfolio-text h2, .portfolio-item:hover .portfolio-text p {
    left: 0;
    opacity: 1; }
  .portfolio-item:hover .portfolio-text p:after {
    width: 40px;
    left: -50px; }

/*-----------------------
	Testimonial Section
-------------------------*/
.testimonial-section {
  /* background-image: url("../img/test-bg.png"); */
  background-size: cover;
  background-position: top center;
  background-color: #298ef6; }

.single-testimonial {
  padding-top: 50px; }
  .single-testimonial .qut {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    font-size: 80px;
    line-height: 80px; }
  .single-testimonial p {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.7;
    margin-bottom: 40px;
    font-style: italic; }
  .single-testimonial h2 {
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px; }
  .single-testimonial span {
    font-size: 13px;
    color: #fff; }

.test-slider .owl-nav {
  padding-top: 40px;
  text-align: right; }
  .test-slider .owl-nav .owl-prev,
  .test-slider .owl-nav .owl-next {
    width: 80px;
    height: 40px;
    background: #fff;
    text-align: center;
    display: inline-block;
    margin-right: 20px;
    padding-top: 8px;
    border-radius: 50px;
    transition: all 0.4s ease 0s; }
    .test-slider .owl-nav .owl-prev:hover,
    .test-slider .owl-nav .owl-next:hover {
      background: #222;
      color: #fff; }

/*-----------------------
	Contact Section
-------------------------*/
.contact-section {
  background: #f2f2f2;
}

.contact-info {
  list-style: none; }
  .contact-info li {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 8px 11px 40px rgba(88, 86, 86, 0.11); }
    .contact-info li h3 {
      font-size: 17px;
      margin-bottom: 10px; }
    .contact-info li p {
      margin-bottom: 0; }

.con-info {
  padding: 0 50px;
  text-align: center;
  margin-bottom: 80px; }
  .con-info .subtitle {
    font-size: 12px;
    margin-bottom: 15px; }
  .con-info h2 {
    font-size: 18px;
    line-height: 1.6;
    font-weight: 500; }

.contact-form input {
  font-size: 12px;
  width: 100%;
  margin-bottom: 30px;
  height: 50px;
  padding: 0 15px;
  border: 1px solid #ebebeb;
  background: #f6f8f9; }
.contact-form textarea {
  font-size: 12px;
  width: 100%;
  height: 140px;
  padding: 15px;
  margin-bottom: 30px;
  border: 1px solid #ebebeb;
  background: #f6f8f9; }
.contact-form #send-form.done {
  background: #0fa049;
  border-color: #0fa049;
  color: #fff; }
.contact-form #send-form.error {
  background: #e20808;
  border-color: #e20808;
  color: #fff; }

/*--------------------
	Footer Section
----------------------*/
.footer-section {
  background: #000; }
  .footer-section .social {
    text-align: center;
    margin-bottom: 30px; }
    .footer-section .social a {
      font-size: 18px;
      color: #f9f9f9;
      display: inline-block;
      margin: 0 10px;
      transition: all 0.4s; }
      .footer-section .social a:hover {
        color: #444; }

.copyright {
  text-align: center;
  font-size: 12px;
  color: #444;
  line-height: 2; }

/*--------------------
	Responsive
----------------------*/
/* Medium screen : 992px. */
/* @media only screen and (min-width: 701px) and (max-width: 1199px) {
  .nav-section {
    visibility: hidden;
  }
  .nav-section.active {
    width: 50%; } } */
/* Tablet :768px. */
/* Large Mobile :480px. */
@media only screen and (max-width: 767px) {

  .nav-section.active {
    width: 100%; }

  .intro-content h2 {
    font-size: 65px; }

  .intro-content h3 {
    font-size: 15px; }
}

/* small Mobile :320px. */
@media only screen and (max-width: 479px) {
  .main-manu {
    padding-left: 50px;
    padding-right: 50px; }

  .main-manu a {
    font-size: 25px; }

  .social-links {
    left: 50px; }

  .social-links a {
    font-size: 18px;
    margin-right: 15px; }

  .intro-content h2 {
    font-size: 40px; }

  .intro-content h3 {
    font-size: 14px; }

  .nav-toggle {
    right: 20px;
  }
}

/* common */

.long-text {
  margin-bottom: 10px;
}

.clamp {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}
